<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="0" :dialogVisible2="topDialogVisible"> </top>
      <div class="banner-box width-100 flex-row justify-content-center align-items-center">
        <div class="width-50 flex-row justify-content-spaceBetween align-items-center">
          <div class="">
            <p class="big-size fw-bold white">需求大厅</p>
            <p class="white fs-mid">找找有没有合适您的~</p>
          </div>
          <img src="../assets/image/09.png" @click="gotoReleaseDemand" class="release-btn pointer" alt="" />
        </div>
        <!--        <img src="../assets/image/01.png" width="100%" alt="" />-->
      </div>
      <div class="container-box">
        <el-row>
          <el-col :span="8" v-for="(item, index) in demandList" :key="index">
            <div class="width-100 demand-box flex-row justify-content-center align-items-center pointer" @click="$router.push(`/demandDetail?id=${item.id}`)">
              <div class="width-100 br-mid box-shadow bg-white van-overflow-hidden item">
                <!-- <img class="demand-picture" :src="item.otherFile" width="100%" alt="" fit="contain"/> -->
                <van-image class="demand-picture" :src="item.otherFile" width="100%" fit="contain"></van-image>
                <div class="width-100 bg-white br-mid text-box">
                  <p class="fs-big width-100 cut-text black fw-mid margin-bs">{{ item.title }}</p>
                  <p class="fs-mid darkgrey width-100 cut-text margin-bs"><span class="margin-r fs-mid">需求分类</span>{{ item.copyrightTypeName }}</p>
                  <p class="fs-mid darkgrey width-100 cut-text line-height2"><span class="margin-r fs-mid">版权用途</span>{{ item.copyrightUsageName }}</p>
                  <p class="width-100 textAlign-r blue fs-mid">了解详情 ></p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--      分页-->
        <div class="pagination width-100 textAlign-c">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[30, 60, 90]"
            :page-size="30"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { getTranCopyrightDemandUnAuth } from '@/api/tranCopyrightDemand';
import * as _ from 'lodash';
import { getTextByValue } from '@/util/helper';
import { getDict } from '@/api/dict';

export default {
  name: 'demandHall',
  components: {
    top,
    bottom
  },
  data() {
    return {
      topDialogVisible: false,
      currentPage: 1,
      demandList: [],
      copyrightDemandParams: {
        copyrightTypeOn: null,
        pageNum: 1,
        pageSize: 30
      },
      total: 0,
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH
    };
  },
  async created() {
    await this.setDist();
    this.getTranCopyrightDemandList();
  },
  methods: {
    async setDist() {
      return Promise.all([
        getDict('tran_copyright_usage')
      ]).then((rows) => {
        this.tranCopyrightUsageDist = rows[0];
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.copyrightDemandParams.pageNum = 1;
      this.copyrightDemandParams.pageSize = val;
      // this.currentPage = 1;
      this.getTranCopyrightDemandList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.copyrightDemandParams.pageNum = val;
      this.getTranCopyrightDemandList();
    },
    async getTranCopyrightDemandList() {
      const copyrightDemandList = await getTranCopyrightDemandUnAuth(this.copyrightDemandParams);
      this.total = copyrightDemandList.total;
      this.demandList = copyrightDemandList.rows.map(row => {
          return {
            copyrightUsageName: getTextByValue(this.tranCopyrightUsageDist, row.copyrightUsage),
            otherFile: row.otherFiles?`${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${row.otherFiles.split(',')[0]}`:null,
            ..._.pick(row, ['id', 'title', 'companyName', 'copyrightTypeName'])
          }
      })
    },
    async gotoReleaseDemand() {
      if (localStorage.getItem('bqsb_login')) {
        // 登录存在
        this.$router.push('/releaseDemand')
      }else {
        this.topDialogVisible = false;
        this.topDialogVisible = true;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .banner-box {
    background: url('../assets/image/01.png') no-repeat;
    background-size: 100% 100%;
    padding: 100px 0;
    .big-size {
      font-size: 3rem;
    }
  }
  .container-box {
    padding: 40px 0;
    .demand-box {
      margin-bottom: 50px;
      .item {
        margin: 0 15px;
      }
    }
  }
  .text-box {
    position: relative;
    top: -10px;
    padding: 20px 20px 10px;
  }
  .release-btn {
    width: 88px;
  }
}
  .demand-picture {
    height: 320px;
  }
</style>
